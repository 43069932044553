import React from "react"
import axios from "axios"
import { Provider } from "react-redux"
import { setConfig } from "./src/state/actions"
import { store } from "./src/state/store"
import "core-js/stable"
import "regenerator-runtime/runtime"
import focusWithin from "./polyfills/focusWithin"
import "./src/styles/tds.scss"
import "trv-ebus-tcom-reactcomponents/dist/index.css"

const onClientEntry = async () => {
  try {
    let response = await axios.get("/config/settings.json")
    store.dispatch(setConfig(response.data))
  } catch (ex) {
    console.log(ex)
  }

  focusWithin(document)
}

const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

const onRouteUpdate = ({ location }) =>
  window.utag &&
  window.utag.view({
    dl: location.href
  })

export { onClientEntry, wrapRootElement, onRouteUpdate }
