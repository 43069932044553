import { SET_CONFIG } from "../actionTypes"

const initialState = {}

export const configReducer = (state = initialState, action) => {
  let newState = state
  if (action.type == SET_CONFIG) {
    newState = {
      ...state,
      ...action.payload,
    }
  }

  return newState
}
